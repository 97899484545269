<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="row">
        <!-- <div class="col-4">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4>Jml User <b class="text-bold">{2}</b></h4>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary btn-sm float-right">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col">
          <div class="card">
            <div class="card-body">
              <!-- {{ monitoringData }} -->
              <div>
                Jml TTE Request:
                <span v-if="monitoringData.request">
                  <span
                    v-for="(value, key) in monitoringData.request"
                    :key="key"
                  >
                    {{ value.status }} <b> {{ value.statusCount }} </b> | &nbsp;
                  </span>
                </span>
              </div>
              <hr />
              <div>
                Jml Proses TTE: Process
                <span v-if="monitoringData.process">
                  <span
                    v-for="(value, key) in monitoringData.process"
                    :key="key"
                  >
                    {{ value.status }} <b> {{ value.statusCount }} </b> | &nbsp;
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-info mb-10" role="alert">
            {{ message }}
          </div>

          <ul class="nav nav-tabs nav-tabs-line">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-toggle="tab"
                href="#kt_tab_pane_1"
                @click="currentTab = 'tte'"
              >
                Daftar Request TTE</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#kt_tab_pane_2"
                @click="currentTab = 'userTte'"
              >
                Daftar User TTE</a
              >
            </li>
          </ul>
          <div class="tab-content mt-5" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_2"
            >
              <BaseTable
                :columns="columns"
                :service="`esign-service/request?service_id=${$route.params.id}`"
                ref="table"
                @actionButton="handleAction"
                @selectedRows="handleSelected"
              >
                <template #action-button="{ data }">
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="handleAction({ type: 'detail', data: data.value })"
                  >
                    Detail
                  </button>
                </template>
              </BaseTable>
            </div>
            <div
              class="tab-pane fade"
              id="kt_tab_pane_2"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_2"
            >
              <BaseTable
                :columns="userColumns"
                :service="`esign-service/user?service_id=${$route.params.id}`"
                ref="tableUserTte"
                @actionButton="handleAction"
                @selectedRows="handleSelected"
              >
                <!-- <template #action-button="{ data }">
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="handleAction({ type: 'detail', data: data.value })"
                  >
                    Detail
                  </button>
                </template> -->
              </BaseTable>
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { toRaw } from "vue";
// import { Form, Field, ErrorMessage } from "vee-validate";
import BaseTable from "/src/components/BaseTable.vue";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    BaseTable,
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      base_url: yup.string().required("base_url is required!"),
      auth_username: yup.string().required("auth_username is required!"),
      auth_password: yup.string().required("auth_password is required!"),
      status: yup.string().required("status is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: {
        base_url: "",
        auth_username: "",
        auth_password: "",
        status: "",
      },
      currentTab: "tte",
      schema,
      userColumns: [
        // { field: "id", title: "ID", isUnique: true, type: "string" },
        { field: "name", title: "Nama" },
        { field: "nik", title: "NIK" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions" },
      ],
      columns: [
        { field: "id", title: "ID", isUnique: true, type: "string" },
        { field: "esign_user.name", title: "User" },
        { field: "status", title: "Status" },
        { field: "timestamp", title: "Timestamp" },
        // { field: "createdAt", title: "Create At" },
        // { field: "updatedAt", title: "Updated At" },
        { field: "actions", title: "Actions" },
      ],
      monitoringData: {
        process: {},
        request: {},
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    this.init();
    // this.getListData();
  },
  methods: {
    async init() {
      const BaseService = new Service(
        `esign-service/request/monitor?service_id=${this.$route.params.id}`
      );
      const { data } = await BaseService.getData();
      this.monitoringData = data;
    },
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("esign-service");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      const BaseService = new Service("esign-service");
      try {
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleAction(context) {
      const { type, data } = context;
      const rawData = toRaw(data);

      console.log(type, this.currentTab);

      if (this.currentTab === "userTte") {
        switch (type) {
          case "create":
            this.$router.push({
              name: "esign-service-create-user",
              params: { serviceId: this.$route.params.id },
            });
            break;
          case "view":
            this.$router.push({
              name: "esign-service-update-user",
              params: { serviceId: this.$route.params.id, id: data.id },
            });
            break;
          case "delete":
            this.deleteConfim(data.id);
            break;
        }
      } else {
        switch (type) {
          case "create":
            this.$router.push({
              name: "esign-service-create-tte",
              params: { serviceId: this.$route.params.id },
            });
            break;
          case "detail":
            this.$router.push({
              name: "esign-service-request-detail",
              params: {
                serviceId: this.$route.params.id,
                requestId: rawData.id,
              },
            });
            break;
        }
      }
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log();
            this.deleteUserTteAction(id);
          }
        });
    },
    async deleteUserTteAction(id) {
      try {
        const BaseService = new Service("esign-service/user");
        const { message } = await BaseService.deleteData(id);
        this.message = message;
        this.$refs.tableUserTte.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(context) {
      console.log(context);
    },
  },
};
</script>
